// Here you can add other styles
.switch-large .form-check-input {
    width: 3rem;
    height: 1.5rem;
    background-size: 50%;
}

.switch-large .form-check-input:before {
    width: 1.5rem;
    height: 1.5rem;
}

.switch-large .form-check-label {
    font-size: 1.2rem;
    /* Memperbesar teks label */
    margin-left: 0.5rem;
    /* Jarak antara switch dan label */
}